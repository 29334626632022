.menu-enabled {
  width: 16.66667%;
  transition: 1s;
}

.menu-disabled {
  width: 80px;
}

.footer-section {
  background-color: #f2f2f5;
  width: 100%;
}

.footer-row {
  display: flex;
  justify-content: space-between;
  width: 95%;
  margin: auto;
  padding: 15px 0;
  font-size: 12px;
  color: #9e9e9e;
}

.events-input-search {
  width: 25%;
}

.events-button-add {
  height: 35px;
}

.events-button-add-lg {
  min-height: 35px;
}

.bx-home-circle:before {
  color: $body-color;
}

.bx-calendar:before {
  color: $body-color;
}

.bx-history:before {
  color: #a3a6a8;
}

.localization {
  position: absolute;
  background-color: white;
  border-radius: 5px;
  z-index: 2;
  margin: 0;
  padding: 0;
}

.localizationLi {
  list-style-type: none;
  padding: 8px;
  border-radius: 5px;
  cursor: pointer;
}
.localizationLi:hover {
  background-color: #e8edf2;
}
.progress-bar {
  margin-top: 3rem !important;
}
.progress-bar-rect-wrap-container {
  background-color: white !important;
}
.inner-rect-bar,
.addRectAnimate {
  background-color: blue !important;
}

.dripicons-arrow-thin-down:before,
.mdi-file-cabinet::before,
.dripicons-gear:before {
  color: #495057;
}

.tagDriver {
  background-color: #77cf80;
  padding: 5px;
  color: white;
  border-radius: 20px;
  margin-right: 5px;
}

.tagDriver:hover {
  color: white;
  cursor: default;
}

.colorpiker-certificate {
  margin-top: 0 !important;
}

.select-disable {
  background-color: #bdbdbd;
}

.groups-match {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.title-theme-match {
  font-size: 15px;
  margin: 0 0 7px 0;
  font-weight: 600;
  color: #495057;
}

.title-theme-match:hover {
  color: #495057;
}

// .group-btn-match {
// }

.div-totalizer {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.option-totalizer {
  display: flex;
  flex-direction: column;
  // width: 210px;
  // height: 85px;
  background-color: white;
  margin: 10px;
  padding: 0 10px;
  justify-content: center;
  border-radius: 7px;
  transition: 0.3s;
}

.option-totalizer-selected {
  background-color: #8585ec;
  color: white;
}

.option-totalizer:hover {
  background-color: #8585ec;
  color: white;
}

.icon-totalizer {
  color: #e0e013;
  margin-right: 10px;
  font-size: 18px;
}

.downloadCloud {
  background-color: #34c38f;
  color: white;
  height: 35px;
  border-radius: 30px;
  width: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.5s;
}

.downloadCloud:hover {
  background-color: #2ca67a;
  color: white;
}

.btn-reset-table {
  color: white;
  background-color: #34c38f;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 30px;
  width: 100px;
  transition: all 0.5s;
}

.btn-reset-table:hover {
  background-color: #2ca67a;
  color: white;
}

.div-chatfixed {
  display: flex;
  background-color: white;
  padding: 10px;
  border-radius: 10px;
  min-width: 200px;
}

.img-chatfixed {
  width: 50px;
  height: 50px;
  border-radius: 50px;
  margin-right: 5px;
}

.data-chatfixed {
  display: flex;
  flex-direction: column;
}

.name-chatfixed {
  color: black;
  font-weight: bold;
}

.msg-chatfixed {
  color: black;
}

.icon-chatfix {
  color: blue;
  font-size: 17px;
  cursor: pointer;
  display: flex;
  align-items: center;
  margin-left: 10px;
  background-color: white;
  height: 35px;
  width: 35px;
  justify-content: center;
  border-radius: 50px;
}

.dropdown-div {
  position: relative;
  display: flex;
}

.dropdown-content {
  display: none;
  // display: flex;
  position: absolute;
  right: 25px;
  top: 5px;
  background-color: #f9f9f9;
  min-width: 160px;
  height: auto !important;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
  flex-direction: column;
}

// .dropdown-div:hover .dropdown-content {
//   display: block;
// }

.option-drop {
  height: 50px;
  display: flex;
  align-items: center;
  color: black;
  margin: 0 !important;
  padding-left: 5px;
}

.option-drop:hover {
  color: black;
  background-color: #eff2f7;
}

.label-tooltip {
  display: flex !important;
  align-items: center !important;
}

.div-config-app {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin-bottom: 20px;
}

.icon-trash {
  color: white;
  font-size: 20px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  background: red;
  height: 40px;
  width: 40px;
  border-radius: 50%;
}

.icon-password {
  margin-left: 10px;
  background-color: #d4dbf9;
  width: 20px;
  height: 20px;
  color: rgb(13, 110, 253);
  justify-content: center;
  display: flex;
  border-radius: 50%;
}

.div-options-modal {
  & button {
    margin: 0 5px;
  }
}

.div-list-tickets {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-wrap: wrap;
  margin-top: 10px;
}

.div-tickets {
  display: flex;
  width: 50%;
  justify-content: space-between;
  align-items: center;
  border: 1px solid #c6c6c6;
  padding: 15px;
  border-radius: 10px;
  margin: 5px 0;
}

.icon-delete-doc {
  background-color: red;
  color: white;
  width: 25px;
  height: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
}

.icon-add-user {
  color: white;
  padding: 20px;
  font-size: 30px;
  border-radius: 50%;
  cursor: pointer;
}

.div-select-in-range {
  display: flex;
  align-items: center;
}

.a-status-payment {
  color: white;
  padding: 5px 0;
  border-radius: 15px;
  width: 100px;
  display: flex;
  text-align: center;
  justify-content: center;
}

@keyframes is-rotating {
  to {
    transform: rotate(1turn);
  }
}

.circle-loading {
  border: 6px solid #e5e5e5;
  border-top-color: #51d4db;
  width: 60px;
  height: 60px;
  display: flex;
  border-radius: 50%;
  animation: is-rotating 1s infinite;
}

.line-underline {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #e2e2e2;
  padding: 10px 0;
  margin: 5px 0;
}

.line-underline a {
  width: 50%;
}

.product-drop {
  display: flex !important;
  align-items: center;
  font-size: 11px !important;
  cursor: pointer;
  user-select: none;
}

.icon-product-drop {
  margin-left: 5px;
  font-size: 20px;
}

.div-email-sended {
  box-shadow: 0px 0px 19px -8px rgba(0, 0, 0, 0.75);
  padding: 20px 10px;
  border-radius: 10px;
  margin: 20px 0;
}

.logistic-all-topics {
  display: flex;
  justify-content: center;
}

.logistic-topic {
  width: 300px;
  color: white;
  text-align: center;
  margin: 0 10px;
  padding: 15px;
  user-select: none;
  border-radius: 10px;
}

.logistics-title-topic {
  color: white;
}

.logistic-right-topic {
  margin: 5px;
  width: 250px;
  height: 100px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
}

.icon-topic {
  color: white;
  border: 2px solid white;
  width: 33px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  font-size: 18px;
}

.icon-eye-logistics {
  background-color: white;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #5e5eff;
}

.div-all-progress {
  width: 100%;
  height: 8px;
  background-color: #acacac;
  border-radius: 12px;
}

.div-current-progress {
  height: 8px;
  background-color: white;
  border-radius: 12px;
}

.div-data-right-topic {
  width: 70%;
  text-align: center;
}

.div-data-icon {
  width: 80px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  margin: 10px;
  text-align: center;
}

.i-icon {
  font-size: 20px;
  border: 1px solid #f0f0f0;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 10px 10px -5px;
  width: 45px;
  height: 45px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 7px;
  margin-bottom: 5px;
  cursor: pointer;
}

.btn-view-email{
  display: flex;
  align-items: center;
  border-bottom: 1px solid #c6c6c6;
  color: black;
  padding: 0 10px;
}

@media (max-width: 1420px) {
  .logistic-all-topics {
    flex-direction: column;
  }

  .logistic-topic {
    width: 100%;
    margin: 0 0 10px;
  }
}

@media (max-width: 590px) {
  .groups-match {
    display: none;
  }

  .div-totalizer {
    flex-direction: column;
  }

  .div-config-app {
    justify-content: center;
  }

  .icon-topic {
    margin-right: 10px;
  }

  .div-data-right-topic {
    width: 100%;
    text-align: center;
  }
}
